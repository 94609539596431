import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { LokaliseApi } from './api';
import { Project } from './types';
import { ResponseError } from '@backstage/errors';

export class LokaliseClient implements LokaliseApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async getProject(projectId: string): Promise<Project> {
    return await this.get(`projects/${projectId}`);
  }

  private async get<T>(path: string): Promise<T> {
    // eslint-disable-next-line prefer-template
    const baseUrl = await this.discoveryApi.getBaseUrl('lokalise') + '/';
    const response = await this.fetchApi.fetch(new URL(path, baseUrl));

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }
}
