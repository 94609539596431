import { TableColumn } from "@backstage/core-components";
import { CatalogTableRow } from "@backstage/plugin-catalog";
import React from "react";
import AzureFuntionsLogo from '../../assets/img/azure-functions.svg';
import KubernetesLogo from '../../assets/img/kubernetes.svg';
import WindowsLogo from '../../assets/img/windows.svg';

export const customColumnFactories = Object.freeze({
    createDeploymentTargetColumn(): TableColumn<CatalogTableRow> {
        return {
            title: '',
            field: 'entity.annotations',
            width: 'auto',
            render: ({ entity }) => {
                switch (entity.metadata.annotations && entity.metadata.annotations['rr-wfm.com/deployment-target']) {
                    case 'azure-functions':
                        return ( <img src={AzureFuntionsLogo} width={16} height={16} /> );
                    case 'kubernetes':
                        return ( <img src={KubernetesLogo} width={16} height={16} /> );
                    case 'windows':
                        return ( <img src={WindowsLogo} width={16} height={16} /> );
                    default:
                        return ( <span>?</span> );
                }
            },
        }
    }
})