import { Box, Checkbox, FormControlLabel, makeStyles, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DefaultEntityFilters, EntityFilter, useEntityList } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import React, { ReactNode, useState } from 'react';

class EntityDeploymentTargetFilter implements EntityFilter {
  constructor(readonly values: string[]) {}
  filterEntity(entity: Entity): boolean {
    const tier = entity.metadata.annotations?.['rr-wfm.com/deployment-target'];
    return tier !== undefined && this.values.includes(tier);
  }
}

export type CustomFilters = DefaultEntityFilters & {
  deploymentTargets?: EntityDeploymentTargetFilter;
};

const useStyles = makeStyles(
  {
    root: {},
    input: {},
    label: {},
  },
  {
    name: 'CatalogReactEntityProcessingStatusPickerPicker',
  },
);

export const EntityDeploymentTargetPicker = () => {
  // The deploymentTargets key is recognized due to the CustomFilter generic
  const classes = useStyles();
  const { updateFilters } = useEntityList<CustomFilters>();

  const [selectedDeploymentTargets, setSelectedDeploymentTargets] = useState<string[]>(
    [],
  );

  // Toggles the value, depending on whether it's already selected
  function onChange(values: string[]) {
    updateFilters({
      deploymentTargets: values.length
        ? new EntityDeploymentTargetFilter(values)
        : undefined,
    });
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const availableDeploymentTargets = ['windows', 'kubernetes', 'azure-functions'];
  return (
    <Box className={classes.root} pb={1} pt={1}>
      <Typography className={classes.label} variant="button" component="label">
        Deployment Target
        <Autocomplete<string, true>
          PopperComponent={popperProps => (
            <div {...popperProps}>{popperProps.children as ReactNode}</div>
          )}
          multiple
          disableCloseOnSelect
          options={availableDeploymentTargets}
          value={selectedDeploymentTargets}
          onChange={(_: object, value: string[]) => {
            console.log(value);
            setSelectedDeploymentTargets(value);
            onChange(value);
          }}
          renderOption={(option, { selected }) => (
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
              }
              onClick={event => event.preventDefault()}
              label={option}
            />
          )}
          size="small"
          popupIcon={
            <ExpandMoreIcon data-testid="deployment-target-picker-expand" />
          }
          renderInput={params => (
            <TextField
              {...params}
              className={classes.input}
              variant="outlined"
            />
          )}
        />
      </Typography>
    </Box>
  );
};