import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

import MontserratRegular from '../assets/fonts/Montserrat-Regular.woff2';

const montserratRegularFont = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('My-Custom-Font'),
    url(${MontserratRegular}) format('woff2'),
  `,
};

const rrThemeColors = {
  brandPrimary: 'rgb(67, 93, 199)',
  brandIndigo: 'rgb(67, 93, 199)',
  link: 'rgb(102, 158, 255)',
  brand90: 'rgb(0, 37, 102)',
  brandLight: 'rgb(0, 56, 153)',
  danger: 'rgb(171, 38, 23)',
  dangerBackground: 'rgb(243, 177, 170)',
  warning: 'rgb(165, 92, 9)',
  warningBackground: 'rgb(250, 212, 168)',
  info: 'rgb(39, 57, 130)',
  success: 'rgb(13, 110, 84)',
  grey10: 'rgb(245, 245, 245)',
  white: 'rgb(255, 255, 255)',
  background: 'rgb(27, 27, 32)',
  cardBackground: 'rgb(52, 53, 59)',
};

export const rrThemeDark = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        // Use the lighter link color to improve contrast and readability.
        main: rrThemeColors.link,
      },
      secondary: {
        main: rrThemeColors.brandIndigo,
      },
      error: {
        main: rrThemeColors.danger,
      },
      warning: {
        main: rrThemeColors.warning,
      },
      info: {
        main: rrThemeColors.info,
      },
      success: {
        main: rrThemeColors.success,
      },
      background: {
        default: rrThemeColors.background,
        paper: rrThemeColors.cardBackground,
      },
      banner: {
        info: rrThemeColors.info,
        error: rrThemeColors.danger,
        text: rrThemeColors.white,
        link: rrThemeColors.grey10,
      },
      errorBackground: rrThemeColors.dangerBackground,
      warningBackground: rrThemeColors.warningBackground,
      infoBackground: rrThemeColors.brand90,
      navigation: {
        background: rrThemeColors.brand90,
        indicator: rrThemeColors.info,
        color: rrThemeColors.white,
        selectedColor: rrThemeColors.white,
        navItem: {
          hoverBackground: rrThemeColors.info,
        },
        submenu: {
          background: rrThemeColors.brandLight,
        },
      },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: 'Montserrat',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#001E50', '#425CC7'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#ED6B6A', '#EC5A59'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#ED6B6A', '#EC5A59'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#16C093', '#13AC83'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#8572EE', '#6750EA'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#0EB8B1', '#11D0C9'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        a: { textDecoration: 'none' },
        '@font-face': [montserratRegularFont],
      },
    },
  },
});
