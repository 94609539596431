import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 120,
  },
  path: {
    fill: '#7df3e1',
  },
});
const RRLogo = () => {
  const classNames = useStyles();

  return (
    <svg
      className={classNames.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2000 1000"
    >
      <style type="text/css">
        .st0&#123;fill:#FFFFFF;stroke:#00005D;stroke-width:7.4287;stroke-miterlimit:10;&#125;
        .st1&#123;fill:#00005D;&#125;
        .st2&#123;fill:#FFFFFF;&#125;
        .st3&#123;fill:#425CC7;&#125;
        .st4&#123;fill:#ED6B6A;&#125;
        .st5&#123;fill:none;stroke:#FFFFFF;stroke-width:7.4287;stroke-miterlimit:10;&#125;
      </style>
      <g>
        <rect x="1048.07" y="49.04" className="st0" width="901.93" height="901.93"/>
        <g>
          <path className="st1" d="M1243.08,307.27c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74V260.4h20.74
            v7.38c7.78-5.98,17.95-9.57,29.92-9.57C1221.74,258.2,1243.08,279.94,1243.08,307.27z M1222.34,307.27
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C1209.77,338.39,1222.34,324.02,1222.34,307.27z"/>
          <path className="st1" d="M1286.76,212.52v141.62h-20.74V212.52H1286.76z"/>
          <path className="st1" d="M1408.23,260.4v93.75h-20.54v-7.38c-7.98,6.18-18.15,9.77-30.12,9.77c-26.33,0-47.47-21.94-47.47-49.27
            c0-27.33,21.14-49.27,47.47-49.27c11.97,0,22.14,3.59,30.12,9.77v-7.38H1408.23z M1387.69,324.22v-33.91
            c-6.98-9.97-16.75-13.56-27.13-13.56c-17.15,0-29.72,13.76-29.72,30.52s12.57,30.52,29.72,30.52
            C1370.93,337.79,1380.7,334.2,1387.69,324.22z"/>
          <path className="st1" d="M1528.9,301.88v52.26h-20.74v-51.06c0-17.15-9.17-26.33-24.33-26.33c-8.98,0-18.75,4.99-26.13,14.16v63.23
            h-20.74V260.4h20.74v8.38c8.18-6.98,18.35-10.77,30.32-10.77C1512.35,258,1528.9,274.76,1528.9,301.88z"/>
          <path className="st1" d="M1243.08,510.41c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74V463.54h20.74
            v7.38c7.78-5.98,17.95-9.57,29.92-9.57C1221.74,461.35,1243.08,483.09,1243.08,510.41z M1222.34,510.41
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C1209.77,541.53,1222.34,527.17,1222.34,510.41z"/>
          <path className="st1" d="M1357.17,516.6h-73.6c1.99,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C1341.81,461.15,1361.16,484.88,1357.17,516.6z M1283.97,502.24h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C1295.34,478.7,1286.36,489.07,1283.97,502.24z"/>
          <path className="st1" d="M1476.84,510.41c0,28.72-20.74,49.27-49.87,49.27c-29.12,0-49.87-20.54-49.87-49.27s20.74-49.27,49.87-49.27
            C1456.1,461.15,1476.84,481.69,1476.84,510.41z M1456.1,510.41c0-17.75-12.17-30.52-29.12-30.52c-16.95,0-29.12,12.77-29.12,30.52
            c0,17.55,12.17,30.52,29.12,30.52C1443.93,540.93,1456.1,527.97,1456.1,510.41z"/>
          <path className="st1" d="M1599.11,510.41c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74V463.54h20.74
            v7.38c7.78-5.98,17.95-9.57,29.92-9.57C1577.77,461.35,1599.11,483.09,1599.11,510.41z M1578.37,510.41
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C1565.8,541.53,1578.37,527.17,1578.37,510.41z"/>
          <path className="st1" d="M1642.79,415.67v141.62h-20.74V415.67H1642.79z"/>
          <path className="st1" d="M1760.87,516.6h-73.6c1.99,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C1745.51,461.15,1764.86,484.88,1760.87,516.6z M1687.67,502.24h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C1699.04,478.7,1690.06,489.07,1687.67,502.24z"/>
          <path className="st1" d="M1182.44,745.87c12.17,0,20.54-3.99,20.54-11.37c0-5.58-4.59-9.57-12.57-10.97l-20.74-3.59
            c-14.36-2.39-27.73-9.57-27.73-26.53c0-18.15,15.76-29.32,39.09-29.32c18.15,0,39.09,6.18,41.09,30.72h-19.15
            c-1-10.17-9.97-14.16-21.94-14.16s-18.55,4.39-18.55,10.97c0,4.99,3.59,9.77,14.56,11.57l17.55,2.79
            c14.16,2.59,28.12,8.78,28.12,26.93c0,19.75-17.35,29.92-40.89,29.92c-22.14,0-41.09-8.78-43.48-33.51l19.15,0.2
            C1159.71,741.08,1168.28,745.87,1182.44,745.87z"/>
          <path className="st1" d="M1399.66,706.78v53.66h-20.54v-53.66c0-16.56-8.78-23.74-22.14-23.74c-9.18,0-17.95,3.39-24.93,12.77
            c0.6,2.99,0.8,6.38,0.8,9.77v54.85h-20.54v-54.85c0-16.56-8.78-22.54-20.94-22.54c-9.77,0-18.55,3.99-25.33,13.36v64.03h-20.74
            v-93.75h20.74v7.98c6.98-6.38,15.36-10.37,27.73-10.37c13.36,0,24.14,4.59,31.12,13.56c9.18-8.98,19.75-13.56,33.51-13.56
            C1382.9,664.29,1399.66,679.05,1399.66,706.78z"/>
          <path className="st1" d="M1519.53,666.68v93.75h-20.54v-7.38c-7.98,6.18-18.15,9.77-30.12,9.77c-26.33,0-47.47-21.94-47.47-49.27
            c0-27.33,21.14-49.27,47.47-49.27c11.97,0,22.14,3.59,30.12,9.77v-7.38H1519.53z M1498.98,730.51V696.6
            c-6.98-9.97-16.75-13.56-27.13-13.56c-17.15,0-29.72,13.76-29.72,30.52s12.57,30.52,29.72,30.52
            C1482.23,744.08,1492,740.48,1498.98,730.51z"/>
          <path className="st1" d="M1603.7,664.69v19.55c-14.96,0.6-26.33,6.18-34.71,17.15v59.04h-20.74v-93.75h20.74v12.77
            C1577.57,670.07,1589.33,664.69,1603.7,664.69z"/>
          <path className="st1" d="M1692.05,759.04c-8.18,2.99-14.96,3.79-21.34,3.79c-23.94,0-36.7-14.36-36.7-38.5v-40.29h-22.14v-17.35h22.14
            v-34.51h20.74v34.51h34.71v17.35h-34.71v40.29c0,13.56,6.78,19.35,18.35,19.35c5.58,0,11.17-1.6,15.96-3.59L1692.05,759.04z"/>
          <path className="st1" d="M1797.96,719.74h-73.6c2,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C1782.61,664.29,1801.95,688.03,1797.96,719.74z M1724.76,705.38h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C1736.13,681.84,1727.16,692.21,1724.76,705.38z"/>
          <path className="st1" d="M1875.95,664.69v19.55c-14.96,0.6-26.33,6.18-34.71,17.15v59.04h-20.74v-93.75h20.74v12.77
            C1849.82,670.07,1861.59,664.69,1875.95,664.69z"/>
        </g>
      </g>
      <g>
        <g>
          <circle className="st1" cx="499.65" cy="501.32" r="449.65"/>
        </g>
        <g>
          <g>
            <path className="st2" d="M265.02,544.89h-49.19v75.89h-47.78V381.86h100.83c53.76,0,93.46,32.32,93.46,81.51
              c0,33.38-18.27,59.02-46.73,72.02l62.19,85.38h-57.97L265.02,544.89z M215.83,500.62h51.3c26.35,0,48.13-14.4,48.13-37.24
              c0-22.84-21.78-37.24-48.13-37.24h-51.3V500.62z"/>
            <path className="st2" d="M561.9,620.78l-19.32-18.27c-19.32,15.46-42.16,23.54-68.16,23.54c-53.76,0-89.59-30.21-89.59-75.19
              c0-27.4,15.11-50.24,40.4-63.24c-10.89-14.76-15.81-27.4-15.81-42.51c0-40.4,33.38-68.51,76.24-68.51
              c43.21,0,70.27,28.81,75.19,70.97h-46.38c-2.46-18.97-13.35-29.86-29.16-29.86c-15.81,0-28.11,11.24-28.11,28.11
              c0,18.27,14.76,32.32,45.32,61.13l37.95,36.19l29.51-47.08h48.13l-44.27,70.62c-1.05,1.76-2.11,3.51-3.16,4.92l51.65,49.19H561.9
              z M512.01,573.7l-50.24-47.43c-3.16-3.16-6.32-5.97-9.49-8.78c-14.4,7.73-22.84,19.32-22.84,33.03
              c0,18.62,20.03,34.78,44.97,34.78C487.07,585.29,500.07,581.07,512.01,573.7z"/>
            <path className="st2" d="M745.66,544.89h-49.19v75.89h-47.78V381.86h100.83c53.76,0,93.46,32.32,93.46,81.51
              c0,33.38-18.27,59.02-46.73,72.02l62.19,85.38h-57.97L745.66,544.89z M696.47,500.62h51.3c26.35,0,48.13-14.4,48.13-37.24
              c0-22.84-21.78-37.24-48.13-37.24h-51.3V500.62z"/>
          </g>
        </g>
      </g>
      <g>
        <rect x="1048.07" y="1069.04" className="st3" width="901.93" height="901.93"/>
        <g>
          <path className="st2" d="M1243.08,1327.27c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74V1280.4h20.74
            v7.38c7.78-5.98,17.95-9.57,29.92-9.57C1221.74,1278.2,1243.08,1299.94,1243.08,1327.27z M1222.34,1327.27
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C1209.77,1358.39,1222.34,1344.02,1222.34,1327.27z"/>
          <path className="st2" d="M1286.76,1232.52v141.62h-20.74v-141.62H1286.76z"/>
          <path className="st2" d="M1408.23,1280.4v93.75h-20.54v-7.38c-7.98,6.18-18.15,9.77-30.12,9.77c-26.33,0-47.47-21.94-47.47-49.27
            c0-27.33,21.14-49.27,47.47-49.27c11.97,0,22.14,3.59,30.12,9.77v-7.38H1408.23z M1387.69,1344.22v-33.91
            c-6.98-9.97-16.75-13.56-27.13-13.56c-17.15,0-29.72,13.76-29.72,30.52s12.57,30.52,29.72,30.52
            C1370.93,1357.79,1380.7,1354.2,1387.69,1344.22z"/>
          <path className="st2" d="M1528.9,1321.88v52.26h-20.74v-51.06c0-17.15-9.17-26.33-24.33-26.33c-8.98,0-18.75,4.99-26.13,14.16v63.23
            h-20.74v-93.75h20.74v8.38c8.18-6.98,18.35-10.77,30.32-10.77C1512.35,1278,1528.9,1294.76,1528.9,1321.88z"/>
          <path className="st2" d="M1243.08,1530.41c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74v-140.02
            h20.74v7.38c7.78-5.98,17.95-9.57,29.92-9.57C1221.74,1481.35,1243.08,1503.09,1243.08,1530.41z M1222.34,1530.41
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C1209.77,1561.53,1222.34,1547.17,1222.34,1530.41z"/>
          <path className="st2" d="M1357.17,1536.6h-73.6c1.99,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C1341.81,1481.15,1361.16,1504.88,1357.17,1536.6z M1283.97,1522.24h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C1295.34,1498.7,1286.36,1509.07,1283.97,1522.24z"/>
          <path className="st2" d="M1476.84,1530.41c0,28.72-20.74,49.27-49.87,49.27c-29.12,0-49.87-20.54-49.87-49.27s20.74-49.27,49.87-49.27
            C1456.1,1481.15,1476.84,1501.69,1476.84,1530.41z M1456.1,1530.41c0-17.75-12.17-30.52-29.12-30.52
            c-16.95,0-29.12,12.77-29.12,30.52c0,17.55,12.17,30.52,29.12,30.52C1443.93,1560.93,1456.1,1547.97,1456.1,1530.41z"/>
          <path className="st2" d="M1599.11,1530.41c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74v-140.02
            h20.74v7.38c7.78-5.98,17.95-9.57,29.92-9.57C1577.77,1481.35,1599.11,1503.09,1599.11,1530.41z M1578.37,1530.41
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C1565.8,1561.53,1578.37,1547.17,1578.37,1530.41z"/>
          <path className="st2" d="M1642.79,1435.67v141.62h-20.74v-141.62H1642.79z"/>
          <path className="st2" d="M1760.87,1536.6h-73.6c1.99,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C1745.51,1481.15,1764.86,1504.88,1760.87,1536.6z M1687.67,1522.24h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C1699.04,1498.7,1690.06,1509.07,1687.67,1522.24z"/>
          <path className="st2" d="M1182.44,1765.87c12.17,0,20.54-3.99,20.54-11.37c0-5.58-4.59-9.57-12.57-10.97l-20.74-3.59
            c-14.36-2.39-27.73-9.57-27.73-26.53c0-18.15,15.76-29.32,39.09-29.32c18.15,0,39.09,6.18,41.09,30.72h-19.15
            c-1-10.17-9.97-14.16-21.94-14.16s-18.55,4.39-18.55,10.97c0,4.99,3.59,9.77,14.56,11.57l17.55,2.79
            c14.16,2.59,28.12,8.78,28.12,26.93c0,19.75-17.35,29.92-40.89,29.92c-22.14,0-41.09-8.78-43.48-33.51l19.15,0.2
            C1159.71,1761.08,1168.28,1765.87,1182.44,1765.87z"/>
          <path className="st2" d="M1399.66,1726.78v53.66h-20.54v-53.66c0-16.56-8.78-23.74-22.14-23.74c-9.18,0-17.95,3.39-24.93,12.77
            c0.6,2.99,0.8,6.38,0.8,9.77v54.85h-20.54v-54.85c0-16.56-8.78-22.54-20.94-22.54c-9.77,0-18.55,3.99-25.33,13.36v64.03h-20.74
            v-93.75h20.74v7.98c6.98-6.38,15.36-10.37,27.73-10.37c13.36,0,24.14,4.59,31.12,13.56c9.18-8.98,19.75-13.56,33.51-13.56
            C1382.9,1684.29,1399.66,1699.05,1399.66,1726.78z"/>
          <path className="st2" d="M1519.53,1686.68v93.75h-20.54v-7.38c-7.98,6.18-18.15,9.77-30.12,9.77c-26.33,0-47.47-21.94-47.47-49.27
            c0-27.33,21.14-49.27,47.47-49.27c11.97,0,22.14,3.59,30.12,9.77v-7.38H1519.53z M1498.98,1750.51v-33.91
            c-6.98-9.97-16.75-13.56-27.13-13.56c-17.15,0-29.72,13.76-29.72,30.52s12.57,30.52,29.72,30.52
            C1482.23,1764.08,1492,1760.48,1498.98,1750.51z"/>
          <path className="st2" d="M1603.7,1684.69v19.55c-14.96,0.6-26.33,6.18-34.71,17.15v59.04h-20.74v-93.75h20.74v12.77
            C1577.57,1690.07,1589.33,1684.69,1603.7,1684.69z"/>
          <path className="st2" d="M1692.05,1779.04c-8.18,2.99-14.96,3.79-21.34,3.79c-23.94,0-36.7-14.36-36.7-38.5v-40.29h-22.14v-17.35
            h22.14v-34.51h20.74v34.51h34.71v17.35h-34.71v40.29c0,13.56,6.78,19.35,18.35,19.35c5.58,0,11.17-1.6,15.96-3.59L1692.05,1779.04
            z"/>
          <path className="st2" d="M1797.96,1739.74h-73.6c2,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C1782.61,1684.29,1801.95,1708.03,1797.96,1739.74z M1724.76,1725.38h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C1736.13,1701.84,1727.16,1712.21,1724.76,1725.38z"/>
          <path className="st2" d="M1875.95,1684.69v19.55c-14.96,0.6-26.33,6.18-34.71,17.15v59.04h-20.74v-93.75h20.74v12.77
            C1849.82,1690.07,1861.59,1684.69,1875.95,1684.69z"/>
        </g>
      </g>
      <g>
        <g>
          <circle className="st1" cx="499.65" cy="1521.32" r="449.65"/>
        </g>
        <g>
          <g>
            <path className="st2" d="M265.02,1564.89h-49.19v75.89h-47.78v-238.91h100.83c53.76,0,93.46,32.32,93.46,81.51
              c0,33.38-18.27,59.02-46.73,72.02l62.19,85.38h-57.97L265.02,1564.89z M215.83,1520.62h51.3c26.35,0,48.13-14.4,48.13-37.24
              c0-22.84-21.78-37.24-48.13-37.24h-51.3V1520.62z"/>
            <path className="st2" d="M561.9,1640.78l-19.32-18.27c-19.32,15.46-42.16,23.54-68.16,23.54c-53.76,0-89.59-30.21-89.59-75.19
              c0-27.41,15.11-50.24,40.4-63.24c-10.89-14.76-15.81-27.4-15.81-42.51c0-40.4,33.38-68.51,76.24-68.51
              c43.21,0,70.27,28.81,75.19,70.97h-46.38c-2.46-18.97-13.35-29.86-29.16-29.86c-15.81,0-28.11,11.24-28.11,28.11
              c0,18.27,14.76,32.32,45.32,61.13l37.95,36.19l29.51-47.08h48.13l-44.27,70.62c-1.05,1.76-2.11,3.51-3.16,4.92l51.65,49.19H561.9
              z M512.01,1593.7l-50.24-47.43c-3.16-3.16-6.32-5.97-9.49-8.78c-14.4,7.73-22.84,19.32-22.84,33.03
              c0,18.62,20.03,34.78,44.97,34.78C487.07,1605.29,500.07,1601.07,512.01,1593.7z"/>
            <path className="st2" d="M745.66,1564.89h-49.19v75.89h-47.78v-238.91h100.83c53.76,0,93.46,32.32,93.46,81.51
              c0,33.38-18.27,59.02-46.73,72.02l62.19,85.38h-57.97L745.66,1564.89z M696.47,1520.62h51.3c26.35,0,48.13-14.4,48.13-37.24
              c0-22.84-21.78-37.24-48.13-37.24h-51.3V1520.62z"/>
          </g>
        </g>
      </g>
      <g>
        <rect x="1048.07" y="2089.04" className="st4" width="901.93" height="901.93"/>
        <g>
          <path className="st2" d="M1243.08,2347.27c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74V2300.4h20.74
            v7.38c7.78-5.98,17.95-9.57,29.92-9.57C1221.74,2298.2,1243.08,2319.94,1243.08,2347.27z M1222.34,2347.27
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C1209.77,2378.39,1222.34,2364.02,1222.34,2347.27z"/>
          <path className="st2" d="M1286.76,2252.52v141.62h-20.74v-141.62H1286.76z"/>
          <path className="st2" d="M1408.23,2300.4v93.75h-20.54v-7.38c-7.98,6.18-18.15,9.77-30.12,9.77c-26.33,0-47.47-21.94-47.47-49.27
            c0-27.33,21.14-49.27,47.47-49.27c11.97,0,22.14,3.59,30.12,9.77v-7.38H1408.23z M1387.69,2364.22v-33.91
            c-6.98-9.97-16.75-13.56-27.13-13.56c-17.15,0-29.72,13.76-29.72,30.52s12.57,30.52,29.72,30.52
            C1370.93,2377.79,1380.7,2374.2,1387.69,2364.22z"/>
          <path className="st2" d="M1528.9,2341.88v52.26h-20.74v-51.06c0-17.15-9.17-26.33-24.33-26.33c-8.98,0-18.75,4.99-26.13,14.16v63.23
            h-20.74v-93.75h20.74v8.38c8.18-6.98,18.35-10.77,30.32-10.77C1512.35,2298,1528.9,2314.76,1528.9,2341.88z"/>
          <path className="st2" d="M1243.08,2550.41c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74v-140.02
            h20.74v7.38c7.78-5.98,17.95-9.57,29.92-9.57C1221.74,2501.35,1243.08,2523.09,1243.08,2550.41z M1222.34,2550.41
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C1209.77,2581.53,1222.34,2567.17,1222.34,2550.41z"/>
          <path className="st2" d="M1357.17,2556.6h-73.6c1.99,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C1341.81,2501.15,1361.16,2524.88,1357.17,2556.6z M1283.97,2542.24h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C1295.34,2518.7,1286.36,2529.07,1283.97,2542.24z"/>
          <path className="st2" d="M1476.84,2550.41c0,28.72-20.74,49.27-49.87,49.27c-29.12,0-49.87-20.54-49.87-49.27s20.74-49.27,49.87-49.27
            C1456.1,2501.15,1476.84,2521.69,1476.84,2550.41z M1456.1,2550.41c0-17.75-12.17-30.52-29.12-30.52
            c-16.95,0-29.12,12.77-29.12,30.52c0,17.55,12.17,30.52,29.12,30.52C1443.93,2580.93,1456.1,2567.97,1456.1,2550.41z"/>
          <path className="st2" d="M1599.11,2550.41c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74v-140.02
            h20.74v7.38c7.78-5.98,17.95-9.57,29.92-9.57C1577.77,2501.35,1599.11,2523.09,1599.11,2550.41z M1578.37,2550.41
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C1565.8,2581.53,1578.37,2567.17,1578.37,2550.41z"/>
          <path className="st2" d="M1642.79,2455.67v141.62h-20.74v-141.62H1642.79z"/>
          <path className="st2" d="M1760.87,2556.6h-73.6c1.99,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C1745.51,2501.15,1764.86,2524.88,1760.87,2556.6z M1687.67,2542.24h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C1699.04,2518.7,1690.06,2529.07,1687.67,2542.24z"/>
          <path className="st2" d="M1182.44,2785.87c12.17,0,20.54-3.99,20.54-11.37c0-5.58-4.59-9.57-12.57-10.97l-20.74-3.59
            c-14.36-2.39-27.73-9.57-27.73-26.53c0-18.15,15.76-29.32,39.09-29.32c18.15,0,39.09,6.18,41.09,30.72h-19.15
            c-1-10.17-9.97-14.16-21.94-14.16s-18.55,4.39-18.55,10.97c0,4.99,3.59,9.77,14.56,11.57l17.55,2.79
            c14.16,2.59,28.12,8.78,28.12,26.93c0,19.75-17.35,29.92-40.89,29.92c-22.14,0-41.09-8.78-43.48-33.51l19.15,0.2
            C1159.71,2781.08,1168.28,2785.87,1182.44,2785.87z"/>
          <path className="st2" d="M1399.66,2746.78v53.66h-20.54v-53.66c0-16.56-8.78-23.74-22.14-23.74c-9.18,0-17.95,3.39-24.93,12.77
            c0.6,2.99,0.8,6.38,0.8,9.77v54.85h-20.54v-54.85c0-16.56-8.78-22.54-20.94-22.54c-9.77,0-18.55,3.99-25.33,13.36v64.03h-20.74
            v-93.75h20.74v7.98c6.98-6.38,15.36-10.37,27.73-10.37c13.36,0,24.14,4.59,31.12,13.56c9.18-8.98,19.75-13.56,33.51-13.56
            C1382.9,2704.29,1399.66,2719.05,1399.66,2746.78z"/>
          <path className="st2" d="M1519.53,2706.68v93.75h-20.54v-7.38c-7.98,6.18-18.15,9.77-30.12,9.77c-26.33,0-47.47-21.94-47.47-49.27
            c0-27.33,21.14-49.27,47.47-49.27c11.97,0,22.14,3.59,30.12,9.77v-7.38H1519.53z M1498.98,2770.51v-33.91
            c-6.98-9.97-16.75-13.56-27.13-13.56c-17.15,0-29.72,13.76-29.72,30.52s12.57,30.52,29.72,30.52
            C1482.23,2784.08,1492,2780.48,1498.98,2770.51z"/>
          <path className="st2" d="M1603.7,2704.69v19.55c-14.96,0.6-26.33,6.18-34.71,17.15v59.04h-20.74v-93.75h20.74v12.77
            C1577.57,2710.07,1589.33,2704.69,1603.7,2704.69z"/>
          <path className="st2" d="M1692.05,2799.04c-8.18,2.99-14.96,3.79-21.34,3.79c-23.94,0-36.7-14.36-36.7-38.5v-40.29h-22.14v-17.35
            h22.14v-34.51h20.74v34.51h34.71v17.35h-34.71v40.29c0,13.56,6.78,19.35,18.35,19.35c5.58,0,11.17-1.6,15.96-3.59L1692.05,2799.04
            z"/>
          <path className="st2" d="M1797.96,2759.74h-73.6c2,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C1782.61,2704.29,1801.95,2728.03,1797.96,2759.74z M1724.76,2745.38h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C1736.13,2721.84,1727.16,2732.21,1724.76,2745.38z"/>
          <path className="st2" d="M1875.95,2704.69v19.55c-14.96,0.6-26.33,6.18-34.71,17.15v59.04h-20.74v-93.75h20.74v12.77
            C1849.82,2710.07,1861.59,2704.69,1875.95,2704.69z"/>
        </g>
      </g>
      <g>
        <g>
          <circle className="st1" cx="499.65" cy="2541.32" r="449.65"/>
        </g>
        <g>
          <g>
            <path className="st2" d="M265.02,2584.89h-49.19v75.89h-47.78v-238.91h100.83c53.76,0,93.46,32.32,93.46,81.51
              c0,33.38-18.27,59.02-46.73,72.02l62.19,85.38h-57.97L265.02,2584.89z M215.83,2540.62h51.3c26.35,0,48.13-14.4,48.13-37.24
              s-21.78-37.24-48.13-37.24h-51.3V2540.62z"/>
            <path className="st2" d="M561.9,2660.78l-19.32-18.27c-19.32,15.46-42.16,23.54-68.16,23.54c-53.76,0-89.59-30.21-89.59-75.19
              c0-27.41,15.11-50.24,40.4-63.24c-10.89-14.76-15.81-27.4-15.81-42.51c0-40.4,33.38-68.51,76.24-68.51
              c43.21,0,70.27,28.81,75.19,70.97h-46.38c-2.46-18.97-13.35-29.86-29.16-29.86c-15.81,0-28.11,11.24-28.11,28.11
              c0,18.27,14.76,32.32,45.32,61.13l37.95,36.19l29.51-47.08h48.13l-44.27,70.62c-1.05,1.76-2.11,3.51-3.16,4.92l51.65,49.19H561.9
              z M512.01,2613.7l-50.24-47.43c-3.16-3.16-6.32-5.97-9.49-8.78c-14.4,7.73-22.84,19.32-22.84,33.03
              c0,18.62,20.03,34.78,44.97,34.78C487.07,2625.29,500.07,2621.07,512.01,2613.7z"/>
            <path className="st2" d="M745.66,2584.89h-49.19v75.89h-47.78v-238.91h100.83c53.76,0,93.46,32.32,93.46,81.51
              c0,33.38-18.27,59.02-46.73,72.02l62.19,85.38h-57.97L745.66,2584.89z M696.47,2540.62h51.3c26.35,0,48.13-14.4,48.13-37.24
              s-21.78-37.24-48.13-37.24h-51.3V2540.62z"/>
          </g>
        </g>
      </g>
      <g>
        <rect x="3068.07" y="49.04" className="st5" width="901.93" height="901.93"/>
        <g>
          <path className="st2" d="M3263.08,307.27c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74V260.4h20.74
            v7.38c7.78-5.98,17.95-9.57,29.92-9.57C3241.74,258.2,3263.08,279.94,3263.08,307.27z M3242.34,307.27
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C3229.77,338.39,3242.34,324.02,3242.34,307.27z"/>
          <path className="st2" d="M3306.76,212.52v141.62h-20.74V212.52H3306.76z"/>
          <path className="st2" d="M3428.23,260.4v93.75h-20.54v-7.38c-7.98,6.18-18.15,9.77-30.12,9.77c-26.33,0-47.47-21.94-47.47-49.27
            c0-27.33,21.14-49.27,47.47-49.27c11.97,0,22.14,3.59,30.12,9.77v-7.38H3428.23z M3407.69,324.22v-33.91
            c-6.98-9.97-16.75-13.56-27.13-13.56c-17.15,0-29.72,13.76-29.72,30.52s12.57,30.52,29.72,30.52
            C3390.93,337.79,3400.7,334.2,3407.69,324.22z"/>
          <path className="st2" d="M3548.9,301.88v52.26h-20.74v-51.06c0-17.15-9.17-26.33-24.33-26.33c-8.98,0-18.75,4.99-26.13,14.16v63.23
            h-20.74V260.4h20.74v8.38c8.18-6.98,18.35-10.77,30.32-10.77C3532.35,258,3548.9,274.76,3548.9,301.88z"/>
          <path className="st2" d="M3263.08,510.41c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74V463.54h20.74
            v7.38c7.78-5.98,17.95-9.57,29.92-9.57C3241.74,461.35,3263.08,483.09,3263.08,510.41z M3242.34,510.41
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C3229.77,541.53,3242.34,527.17,3242.34,510.41z"/>
          <path className="st2" d="M3377.17,516.6h-73.6c1.99,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C3361.81,461.15,3381.16,484.88,3377.17,516.6z M3303.97,502.24h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C3315.34,478.7,3306.36,489.07,3303.97,502.24z"/>
          <path className="st2" d="M3496.84,510.41c0,28.72-20.74,49.27-49.87,49.27c-29.12,0-49.87-20.54-49.87-49.27s20.74-49.27,49.87-49.27
            C3476.1,461.15,3496.84,481.69,3496.84,510.41z M3476.1,510.41c0-17.75-12.17-30.52-29.12-30.52c-16.95,0-29.12,12.77-29.12,30.52
            c0,17.55,12.17,30.52,29.12,30.52C3463.93,540.93,3476.1,527.97,3476.1,510.41z"/>
          <path className="st2" d="M3619.11,510.41c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74V463.54h20.74
            v7.38c7.78-5.98,17.95-9.57,29.92-9.57C3597.77,461.35,3619.11,483.09,3619.11,510.41z M3598.37,510.41
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C3585.8,541.53,3598.37,527.17,3598.37,510.41z"/>
          <path className="st2" d="M3662.79,415.67v141.62h-20.74V415.67H3662.79z"/>
          <path className="st2" d="M3780.87,516.6h-73.6c1.99,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C3765.51,461.15,3784.86,484.88,3780.87,516.6z M3707.67,502.24h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C3719.04,478.7,3710.06,489.07,3707.67,502.24z"/>
          <path className="st2" d="M3202.44,745.87c12.17,0,20.54-3.99,20.54-11.37c0-5.58-4.59-9.57-12.57-10.97l-20.74-3.59
            c-14.36-2.39-27.73-9.57-27.73-26.53c0-18.15,15.76-29.32,39.09-29.32c18.15,0,39.09,6.18,41.09,30.72h-19.15
            c-1-10.17-9.97-14.16-21.94-14.16s-18.55,4.39-18.55,10.97c0,4.99,3.59,9.77,14.56,11.57l17.55,2.79
            c14.16,2.59,28.12,8.78,28.12,26.93c0,19.75-17.35,29.92-40.89,29.92c-22.14,0-41.09-8.78-43.48-33.51l19.15,0.2
            C3179.71,741.08,3188.28,745.87,3202.44,745.87z"/>
          <path className="st2" d="M3419.66,706.78v53.66h-20.54v-53.66c0-16.56-8.78-23.74-22.14-23.74c-9.18,0-17.95,3.39-24.93,12.77
            c0.6,2.99,0.8,6.38,0.8,9.77v54.85h-20.54v-54.85c0-16.56-8.78-22.54-20.94-22.54c-9.77,0-18.55,3.99-25.33,13.36v64.03h-20.74
            v-93.75h20.74v7.98c6.98-6.38,15.36-10.37,27.73-10.37c13.36,0,24.14,4.59,31.12,13.56c9.18-8.98,19.75-13.56,33.51-13.56
            C3402.9,664.29,3419.66,679.05,3419.66,706.78z"/>
          <path className="st2" d="M3539.53,666.68v93.75h-20.54v-7.38c-7.98,6.18-18.15,9.77-30.12,9.77c-26.33,0-47.47-21.94-47.47-49.27
            c0-27.33,21.14-49.27,47.47-49.27c11.97,0,22.14,3.59,30.12,9.77v-7.38H3539.53z M3518.98,730.51V696.6
            c-6.98-9.97-16.75-13.56-27.13-13.56c-17.15,0-29.72,13.76-29.72,30.52s12.57,30.52,29.72,30.52
            C3502.23,744.08,3512,740.48,3518.98,730.51z"/>
          <path className="st2" d="M3623.7,664.69v19.55c-14.96,0.6-26.33,6.18-34.71,17.15v59.04h-20.74v-93.75h20.74v12.77
            C3597.57,670.07,3609.33,664.69,3623.7,664.69z"/>
          <path className="st2" d="M3712.05,759.04c-8.18,2.99-14.96,3.79-21.34,3.79c-23.94,0-36.7-14.36-36.7-38.5v-40.29h-22.14v-17.35h22.14
            v-34.51h20.74v34.51h34.71v17.35h-34.71v40.29c0,13.56,6.78,19.35,18.35,19.35c5.58,0,11.17-1.6,15.96-3.59L3712.05,759.04z"/>
          <path className="st2" d="M3817.96,719.74h-73.6c2,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C3802.61,664.29,3821.95,688.03,3817.96,719.74z M3744.76,705.38h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C3756.13,681.84,3747.16,692.21,3744.76,705.38z"/>
          <path className="st2" d="M3895.95,664.69v19.55c-14.96,0.6-26.33,6.18-34.71,17.15v59.04h-20.74v-93.75h20.74v12.77
            C3869.82,670.07,3881.59,664.69,3895.95,664.69z"/>
        </g>
      </g>
      <path className="st2" d="M2969.29,501.32c0,248.33-201.31,449.65-449.65,449.65S2070,749.65,2070,501.32s201.31-449.65,449.65-449.65
        S2969.29,252.99,2969.29,501.32z M2339.82,620.78h57.97l-62.19-85.38c28.46-13,46.73-38.65,46.73-72.02
        c0-49.19-39.7-81.51-93.46-81.51h-100.83v238.91h47.78v-75.89h49.19L2339.82,620.78z M2235.83,426.13h51.3
        c26.35,0,48.13,14.4,48.13,37.24c0,22.84-21.78,37.24-48.13,37.24h-51.3V426.13z M2642.33,620.78l-51.65-49.19
        c1.05-1.41,2.11-3.16,3.16-4.92l44.27-70.62h-48.13l-29.51,47.08l-37.95-36.19c-30.57-28.81-45.32-42.86-45.32-61.13
        c0-16.86,12.3-28.11,28.11-28.11c15.81,0,26.7,10.89,29.16,29.86h46.38c-4.92-42.16-31.97-70.97-75.19-70.97
        c-42.86,0-76.24,28.11-76.24,68.51c0,15.11,4.92,27.76,15.81,42.51c-25.3,13-40.4,35.84-40.4,63.24c0,44.97,35.84,75.19,89.59,75.19
        c26,0,48.84-8.08,68.16-23.54l19.32,18.27H2642.33z M2494.42,585.29c-24.95,0-44.97-16.16-44.97-34.78c0-13.7,8.43-25.3,22.84-33.03
        c3.16,2.81,6.32,5.62,9.49,8.78l50.24,47.43C2520.07,581.07,2507.07,585.29,2494.42,585.29z M2820.47,620.78h57.97l-62.19-85.38
        c28.46-13,46.73-38.65,46.73-72.02c0-49.19-39.7-81.51-93.46-81.51h-100.83v238.91h47.78v-75.89h49.19L2820.47,620.78z
        M2716.47,426.13h51.3c26.35,0,48.13,14.4,48.13,37.24c0,22.84-21.78,37.24-48.13,37.24h-51.3V426.13z"/>
      <g>
        <rect x="3068.07" y="1069.04" className="st3" width="901.93" height="901.93"/>
        <g>
          <path className="st2" d="M3263.08,1327.27c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74V1280.4h20.74
            v7.38c7.78-5.98,17.95-9.57,29.92-9.57C3241.74,1278.2,3263.08,1299.94,3263.08,1327.27z M3242.34,1327.27
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C3229.77,1358.39,3242.34,1344.02,3242.34,1327.27z"/>
          <path className="st2" d="M3306.76,1232.52v141.62h-20.74v-141.62H3306.76z"/>
          <path className="st2" d="M3428.23,1280.4v93.75h-20.54v-7.38c-7.98,6.18-18.15,9.77-30.12,9.77c-26.33,0-47.47-21.94-47.47-49.27
            c0-27.33,21.14-49.27,47.47-49.27c11.97,0,22.14,3.59,30.12,9.77v-7.38H3428.23z M3407.69,1344.22v-33.91
            c-6.98-9.97-16.75-13.56-27.13-13.56c-17.15,0-29.72,13.76-29.72,30.52s12.57,30.52,29.72,30.52
            C3390.93,1357.79,3400.7,1354.2,3407.69,1344.22z"/>
          <path className="st2" d="M3548.9,1321.88v52.26h-20.74v-51.06c0-17.15-9.17-26.33-24.33-26.33c-8.98,0-18.75,4.99-26.13,14.16v63.23
            h-20.74v-93.75h20.74v8.38c8.18-6.98,18.35-10.77,30.32-10.77C3532.35,1278,3548.9,1294.76,3548.9,1321.88z"/>
          <path className="st2" d="M3263.08,1530.41c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74v-140.02
            h20.74v7.38c7.78-5.98,17.95-9.57,29.92-9.57C3241.74,1481.35,3263.08,1503.09,3263.08,1530.41z M3242.34,1530.41
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C3229.77,1561.53,3242.34,1547.17,3242.34,1530.41z"/>
          <path className="st2" d="M3377.17,1536.6h-73.6c1.99,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C3361.81,1481.15,3381.16,1504.88,3377.17,1536.6z M3303.97,1522.24h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C3315.34,1498.7,3306.36,1509.07,3303.97,1522.24z"/>
          <path className="st2" d="M3496.84,1530.41c0,28.72-20.74,49.27-49.87,49.27c-29.12,0-49.87-20.54-49.87-49.27s20.74-49.27,49.87-49.27
            C3476.1,1481.15,3496.84,1501.69,3496.84,1530.41z M3476.1,1530.41c0-17.75-12.17-30.52-29.12-30.52
            c-16.95,0-29.12,12.77-29.12,30.52c0,17.55,12.17,30.52,29.12,30.52C3463.93,1560.93,3476.1,1547.97,3476.1,1530.41z"/>
          <path className="st2" d="M3619.11,1530.41c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74v-140.02
            h20.74v7.38c7.78-5.98,17.95-9.57,29.92-9.57C3597.77,1481.35,3619.11,1503.09,3619.11,1530.41z M3598.37,1530.41
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C3585.8,1561.53,3598.37,1547.17,3598.37,1530.41z"/>
          <path className="st2" d="M3662.79,1435.67v141.62h-20.74v-141.62H3662.79z"/>
          <path className="st2" d="M3780.87,1536.6h-73.6c1.99,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C3765.51,1481.15,3784.86,1504.88,3780.87,1536.6z M3707.67,1522.24h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C3719.04,1498.7,3710.06,1509.07,3707.67,1522.24z"/>
          <path className="st2" d="M3202.44,1765.87c12.17,0,20.54-3.99,20.54-11.37c0-5.58-4.59-9.57-12.57-10.97l-20.74-3.59
            c-14.36-2.39-27.73-9.57-27.73-26.53c0-18.15,15.76-29.32,39.09-29.32c18.15,0,39.09,6.18,41.09,30.72h-19.15
            c-1-10.17-9.97-14.16-21.94-14.16s-18.55,4.39-18.55,10.97c0,4.99,3.59,9.77,14.56,11.57l17.55,2.79
            c14.16,2.59,28.12,8.78,28.12,26.93c0,19.75-17.35,29.92-40.89,29.92c-22.14,0-41.09-8.78-43.48-33.51l19.15,0.2
            C3179.71,1761.08,3188.28,1765.87,3202.44,1765.87z"/>
          <path className="st2" d="M3419.66,1726.78v53.66h-20.54v-53.66c0-16.56-8.78-23.74-22.14-23.74c-9.18,0-17.95,3.39-24.93,12.77
            c0.6,2.99,0.8,6.38,0.8,9.77v54.85h-20.54v-54.85c0-16.56-8.78-22.54-20.94-22.54c-9.77,0-18.55,3.99-25.33,13.36v64.03h-20.74
            v-93.75h20.74v7.98c6.98-6.38,15.36-10.37,27.73-10.37c13.36,0,24.14,4.59,31.12,13.56c9.18-8.98,19.75-13.56,33.51-13.56
            C3402.9,1684.29,3419.66,1699.05,3419.66,1726.78z"/>
          <path className="st2" d="M3539.53,1686.68v93.75h-20.54v-7.38c-7.98,6.18-18.15,9.77-30.12,9.77c-26.33,0-47.47-21.94-47.47-49.27
            c0-27.33,21.14-49.27,47.47-49.27c11.97,0,22.14,3.59,30.12,9.77v-7.38H3539.53z M3518.98,1750.51v-33.91
            c-6.98-9.97-16.75-13.56-27.13-13.56c-17.15,0-29.72,13.76-29.72,30.52s12.57,30.52,29.72,30.52
            C3502.23,1764.08,3512,1760.48,3518.98,1750.51z"/>
          <path className="st2" d="M3623.7,1684.69v19.55c-14.96,0.6-26.33,6.18-34.71,17.15v59.04h-20.74v-93.75h20.74v12.77
            C3597.57,1690.07,3609.33,1684.69,3623.7,1684.69z"/>
          <path className="st2" d="M3712.05,1779.04c-8.18,2.99-14.96,3.79-21.34,3.79c-23.94,0-36.7-14.36-36.7-38.5v-40.29h-22.14v-17.35
            h22.14v-34.51h20.74v34.51h34.71v17.35h-34.71v40.29c0,13.56,6.78,19.35,18.35,19.35c5.58,0,11.17-1.6,15.96-3.59L3712.05,1779.04
            z"/>
          <path className="st2" d="M3817.96,1739.74h-73.6c2,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C3802.61,1684.29,3821.95,1708.03,3817.96,1739.74z M3744.76,1725.38h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C3756.13,1701.84,3747.16,1712.21,3744.76,1725.38z"/>
          <path className="st2" d="M3895.95,1684.69v19.55c-14.96,0.6-26.33,6.18-34.71,17.15v59.04h-20.74v-93.75h20.74v12.77
            C3869.82,1690.07,3881.59,1684.69,3895.95,1684.69z"/>
        </g>
      </g>
      <g>
        <g>
          <circle className="st2" cx="2519.65" cy="1521.32" r="449.65"/>
        </g>
        <g>
          <g>
            <path className="st1" d="M2285.02,1564.89h-49.19v75.89h-47.78v-238.91h100.83c53.76,0,93.46,32.32,93.46,81.51
              c0,33.38-18.27,59.02-46.73,72.02l62.19,85.38h-57.97L2285.02,1564.89z M2235.83,1520.62h51.3c26.35,0,48.13-14.4,48.13-37.24
              c0-22.84-21.78-37.24-48.13-37.24h-51.3V1520.62z"/>
            <path className="st1" d="M2581.9,1640.78l-19.32-18.27c-19.32,15.46-42.16,23.54-68.16,23.54c-53.76,0-89.59-30.21-89.59-75.19
              c0-27.41,15.11-50.24,40.4-63.24c-10.89-14.76-15.81-27.4-15.81-42.51c0-40.4,33.38-68.51,76.24-68.51
              c43.21,0,70.27,28.81,75.19,70.97h-46.38c-2.46-18.97-13.35-29.86-29.16-29.86c-15.81,0-28.11,11.24-28.11,28.11
              c0,18.27,14.76,32.32,45.32,61.13l37.95,36.19l29.51-47.08h48.13l-44.27,70.62c-1.05,1.76-2.11,3.51-3.16,4.92l51.65,49.19
              H2581.9z M2532.01,1593.7l-50.24-47.43c-3.16-3.16-6.32-5.97-9.49-8.78c-14.4,7.73-22.84,19.32-22.84,33.03
              c0,18.62,20.03,34.78,44.97,34.78C2507.07,1605.29,2520.07,1601.07,2532.01,1593.7z"/>
            <path className="st1" d="M2765.66,1564.89h-49.19v75.89h-47.78v-238.91h100.83c53.76,0,93.46,32.32,93.46,81.51
              c0,33.38-18.27,59.02-46.73,72.02l62.19,85.38h-57.97L2765.66,1564.89z M2716.47,1520.62h51.3c26.35,0,48.13-14.4,48.13-37.24
              c0-22.84-21.78-37.24-48.13-37.24h-51.3V1520.62z"/>
          </g>
        </g>
      </g>
      <g>
        <rect x="3068.07" y="2089.04" className="st4" width="901.93" height="901.93"/>
        <g>
          <path className="st2" d="M3263.08,2347.27c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74V2300.4h20.74
            v7.38c7.78-5.98,17.95-9.57,29.92-9.57C3241.74,2298.2,3263.08,2319.94,3263.08,2347.27z M3242.34,2347.27
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C3229.77,2378.39,3242.34,2364.02,3242.34,2347.27z"/>
          <path className="st2" d="M3306.76,2252.52v141.62h-20.74v-141.62H3306.76z"/>
          <path className="st2" d="M3428.23,2300.4v93.75h-20.54v-7.38c-7.98,6.18-18.15,9.77-30.12,9.77c-26.33,0-47.47-21.94-47.47-49.27
            c0-27.33,21.14-49.27,47.47-49.27c11.97,0,22.14,3.59,30.12,9.77v-7.38H3428.23z M3407.69,2364.22v-33.91
            c-6.98-9.97-16.75-13.56-27.13-13.56c-17.15,0-29.72,13.76-29.72,30.52s12.57,30.52,29.72,30.52
            C3390.93,2377.79,3400.7,2374.2,3407.69,2364.22z"/>
          <path className="st2" d="M3548.9,2341.88v52.26h-20.74v-51.06c0-17.15-9.17-26.33-24.33-26.33c-8.98,0-18.75,4.99-26.13,14.16v63.23
            h-20.74v-93.75h20.74v8.38c8.18-6.98,18.35-10.77,30.32-10.77C3532.35,2298,3548.9,2314.76,3548.9,2341.88z"/>
          <path className="st2" d="M3263.08,2550.41c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74v-140.02
            h20.74v7.38c7.78-5.98,17.95-9.57,29.92-9.57C3241.74,2501.35,3263.08,2523.09,3263.08,2550.41z M3242.34,2550.41
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C3229.77,2581.53,3242.34,2567.17,3242.34,2550.41z"/>
          <path className="st2" d="M3377.17,2556.6h-73.6c1.99,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C3361.81,2501.15,3381.16,2524.88,3377.17,2556.6z M3303.97,2542.24h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C3315.34,2518.7,3306.36,2529.07,3303.97,2542.24z"/>
          <path className="st2" d="M3496.84,2550.41c0,28.72-20.74,49.27-49.87,49.27c-29.12,0-49.87-20.54-49.87-49.27s20.74-49.27,49.87-49.27
            C3476.1,2501.15,3496.84,2521.69,3496.84,2550.41z M3476.1,2550.41c0-17.75-12.17-30.52-29.12-30.52
            c-16.95,0-29.12,12.77-29.12,30.52c0,17.55,12.17,30.52,29.12,30.52C3463.93,2580.93,3476.1,2567.97,3476.1,2550.41z"/>
          <path className="st2" d="M3619.11,2550.41c0,27.33-21.34,49.07-47.67,49.07c-11.97,0-22.14-3.59-29.92-9.57v53.66h-20.74v-140.02
            h20.74v7.38c7.78-5.98,17.95-9.57,29.92-9.57C3597.77,2501.35,3619.11,2523.09,3619.11,2550.41z M3598.37,2550.41
            c0-16.75-12.57-31.12-29.92-31.12c-10.37,0-19.95,3.99-26.93,13.96v34.31c6.98,9.97,16.56,13.96,26.93,13.96
            C3585.8,2581.53,3598.37,2567.17,3598.37,2550.41z"/>
          <path className="st2" d="M3662.79,2455.67v141.62h-20.74v-141.62H3662.79z"/>
          <path className="st2" d="M3780.87,2556.6h-73.6c1.99,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C3765.51,2501.15,3784.86,2524.88,3780.87,2556.6z M3707.67,2542.24h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C3719.04,2518.7,3710.06,2529.07,3707.67,2542.24z"/>
          <path className="st2" d="M3202.44,2785.87c12.17,0,20.54-3.99,20.54-11.37c0-5.58-4.59-9.57-12.57-10.97l-20.74-3.59
            c-14.36-2.39-27.73-9.57-27.73-26.53c0-18.15,15.76-29.32,39.09-29.32c18.15,0,39.09,6.18,41.09,30.72h-19.15
            c-1-10.17-9.97-14.16-21.94-14.16s-18.55,4.39-18.55,10.97c0,4.99,3.59,9.77,14.56,11.57l17.55,2.79
            c14.16,2.59,28.12,8.78,28.12,26.93c0,19.75-17.35,29.92-40.89,29.92c-22.14,0-41.09-8.78-43.48-33.51l19.15,0.2
            C3179.71,2781.08,3188.28,2785.87,3202.44,2785.87z"/>
          <path className="st2" d="M3419.66,2746.78v53.66h-20.54v-53.66c0-16.56-8.78-23.74-22.14-23.74c-9.18,0-17.95,3.39-24.93,12.77
            c0.6,2.99,0.8,6.38,0.8,9.77v54.85h-20.54v-54.85c0-16.56-8.78-22.54-20.94-22.54c-9.77,0-18.55,3.99-25.33,13.36v64.03h-20.74
            v-93.75h20.74v7.98c6.98-6.38,15.36-10.37,27.73-10.37c13.36,0,24.14,4.59,31.12,13.56c9.18-8.98,19.75-13.56,33.51-13.56
            C3402.9,2704.29,3419.66,2719.05,3419.66,2746.78z"/>
          <path className="st2" d="M3539.53,2706.68v93.75h-20.54v-7.38c-7.98,6.18-18.15,9.77-30.12,9.77c-26.33,0-47.47-21.94-47.47-49.27
            c0-27.33,21.14-49.27,47.47-49.27c11.97,0,22.14,3.59,30.12,9.77v-7.38H3539.53z M3518.98,2770.51v-33.91
            c-6.98-9.97-16.75-13.56-27.13-13.56c-17.15,0-29.72,13.76-29.72,30.52s12.57,30.52,29.72,30.52
            C3502.23,2784.08,3512,2780.48,3518.98,2770.51z"/>
          <path className="st2" d="M3623.7,2704.69v19.55c-14.96,0.6-26.33,6.18-34.71,17.15v59.04h-20.74v-93.75h20.74v12.77
            C3597.57,2710.07,3609.33,2704.69,3623.7,2704.69z"/>
          <path className="st2" d="M3712.05,2799.04c-8.18,2.99-14.96,3.79-21.34,3.79c-23.94,0-36.7-14.36-36.7-38.5v-40.29h-22.14v-17.35
            h22.14v-34.51h20.74v34.51h34.71v17.35h-34.71v40.29c0,13.56,6.78,19.35,18.35,19.35c5.58,0,11.17-1.6,15.96-3.59L3712.05,2799.04
            z"/>
          <path className="st2" d="M3817.96,2759.74h-73.6c2,14.16,10.97,24.93,28.52,24.93c9.18,0,16.16-2.79,19.95-8.78h22.74
            c-5.98,16.95-22.54,26.93-42.88,26.93c-29.52,0-49.47-21.34-49.47-49.27c0-27.73,19.75-49.27,48.27-49.27
            C3802.61,2704.29,3821.95,2728.03,3817.96,2759.74z M3744.76,2745.38h52.66c-1.2-15.36-11.17-23.54-25.73-23.54
            C3756.13,2721.84,3747.16,2732.21,3744.76,2745.38z"/>
          <path className="st2" d="M3895.95,2704.69v19.55c-14.96,0.6-26.33,6.18-34.71,17.15v59.04h-20.74v-93.75h20.74v12.77
            C3869.82,2710.07,3881.59,2704.69,3895.95,2704.69z"/>
        </g>
      </g>
      <g>
        <g>
          <circle className="st2" cx="2519.65" cy="2541.32" r="449.65"/>
        </g>
        <g>
          <g>
            <path className="st1" d="M2285.02,2584.89h-49.19v75.89h-47.78v-238.91h100.83c53.76,0,93.46,32.32,93.46,81.51
              c0,33.38-18.27,59.02-46.73,72.02l62.19,85.38h-57.97L2285.02,2584.89z M2235.83,2540.62h51.3c26.35,0,48.13-14.4,48.13-37.24
              s-21.78-37.24-48.13-37.24h-51.3V2540.62z"/>
            <path className="st1" d="M2581.9,2660.78l-19.32-18.27c-19.32,15.46-42.16,23.54-68.16,23.54c-53.76,0-89.59-30.21-89.59-75.19
              c0-27.41,15.11-50.24,40.4-63.24c-10.89-14.76-15.81-27.4-15.81-42.51c0-40.4,33.38-68.51,76.24-68.51
              c43.21,0,70.27,28.81,75.19,70.97h-46.38c-2.46-18.97-13.35-29.86-29.16-29.86c-15.81,0-28.11,11.24-28.11,28.11
              c0,18.27,14.76,32.32,45.32,61.13l37.95,36.19l29.51-47.08h48.13l-44.27,70.62c-1.05,1.76-2.11,3.51-3.16,4.92l51.65,49.19
              H2581.9z M2532.01,2613.7l-50.24-47.43c-3.16-3.16-6.32-5.97-9.49-8.78c-14.4,7.73-22.84,19.32-22.84,33.03
              c0,18.62,20.03,34.78,44.97,34.78C2507.07,2625.29,2520.07,2621.07,2532.01,2613.7z"/>
            <path className="st1" d="M2765.66,2584.89h-49.19v75.89h-47.78v-238.91h100.83c53.76,0,93.46,32.32,93.46,81.51
              c0,33.38-18.27,59.02-46.73,72.02l62.19,85.38h-57.97L2765.66,2584.89z M2716.47,2540.62h51.3c26.35,0,48.13-14.4,48.13-37.24
              s-21.78-37.24-48.13-37.24h-51.3V2540.62z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RRLogo;
