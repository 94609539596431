import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { LokaliseClient, lokaliseApiRef } from './api';
import { rootRouteRef } from './routes';

export const lokalisePlugin = createPlugin({
  id: 'lokalise',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: lokaliseApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new LokaliseClient({ discoveryApi, fetchApi }),
    }),
  ],
});

export const EntityLokaliseCard = lokalisePlugin.provide(
  createComponentExtension({
    name: 'EntityLokaliseCard',
    component: {
      lazy: () => import('./components/LokaliseCard').then(m => m.LokaliseCard),
    },
  }),
);
