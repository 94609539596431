import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

import MontserratRegular from '../assets/fonts/Montserrat-Regular.woff2';

const montserratRegularFont = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('My-Custom-Font'),
    url(${MontserratRegular}) format('woff2'),
  `,
};

export const rrThemeLight = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#001E50', // Brand Primary
      },
      secondary: {
        main: '#425CC7', // Brand Indigo
      },
      error: {
        main: '#E44B39', // Color Danger
      },
      warning: {
        main: '#F38C15', // Color Warning
      },
      info: {
        main: '#425CC7', // Color Info
      },
      success: {
        main: '#17C194', // Color Success
      },
      background: {
        default: '#F4F4F4', // Grey 10
        paper: '#FFFFFF', // White
      },
      banner: {
        info: '#425CC7',
        error: '#E44B39',
        text: '#FFFFFF',
        link: '#F4F4F4',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: '#343b58',
        indicator: '#425CC7',
        color: '#ffffff',
        selectedColor: '#ffffff',
        navItem: {
          hoverBackground: '#425CC7',
        },
        submenu: {
          background: '#3751BD',
        },
      },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: 'Montserrat',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#001E50', '#425CC7'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#ED6B6A', '#EC5A59'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#ED6B6A', '#EC5A59'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#16C093', '#13AC83'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#8572EE', '#6750EA'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#0EB8B1', '#11D0C9'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'a': { "textDecoration": "none" },
        '@font-face': [montserratRegularFont],
      },
    },
  },
});