import { Link, Table, TableColumn } from "@backstage/core-components";
import { useEntity } from "@backstage/plugin-catalog-react";
import React from "react";

interface TestEnvironmentApplication {
    application: string,
    authentication: string,
    link: string
}

export const TestEnvironmentTable = () => {
    const entity = useEntity();
    const environmentName = entity.entity.metadata.name;

    const columns: TableColumn<TestEnvironmentApplication>[] = [
        {
            title: 'Application',
            field: 'application'
        },
        {
            title: 'Authentication',
            field: 'authentication'
        },
        {
            title: 'Link',
            field: 'link',
            render: (row: Partial<TestEnvironmentApplication>) => {
                return (
                    <Link to={row.link || ""}>{row.link}</Link>
                )
            }
        }
    ];

    const data: TestEnvironmentApplication[] = [
        {
            application: 'R&R',
            authentication: 'Username/password',
            link: `https://my.${environmentName}.zeus.corp/`
        },
        {
            application: 'R&R (SSO)',
            authentication: 'Single Sign-On',
            link: `https://my.${environmentName}.zeus.corp/sso/rrintern`
        },
        {
            application: 'Configuration Web',
            authentication: 'Single Sign-On',
            link: `https://services.${environmentName}.zeus.corp/ConfigurationWeb/`
        },
        {
            application: 'A&A',
            authentication: 'Username/password (LastPass)',
            link: `https://portal.${environmentName}.zeus.corp/DeVries.ApplicationServicesWeb/`
        },
    ]

    return (
        <Table<TestEnvironmentApplication>
            columns={columns}
            data={data}
            title={environmentName}
            options={{
                paging: false,
            }} />
    )
}