import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  CatalogTable,
  CatalogTableColumnsFunc,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage, searchPlugin } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog, SignInPage } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import { microsoftAuthApiRef } from '@backstage/core-plugin-api';

import { EntityValidationPage } from '@backstage-community/plugin-entity-validation';

import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import { homePage } from './components/home/HomePage';
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { explorePage } from './components/explore/ExplorePage';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import * as plugins from './plugins';
import { OctopusDeployDropdownFieldExtension } from '@backstage-community/plugin-octopus-deploy';
import { RBACRoot } from '@spotify/backstage-plugin-rbac';
import { SkillExchangePage } from '@spotify/backstage-plugin-skill-exchange';
import { SoundcheckRoutingPage } from '@spotify/backstage-plugin-soundcheck';
import { InsightsPage, insightsPlugin, InsightsSurveyLoader } from '@spotify/backstage-plugin-insights';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { DefaultFilters } from '@backstage/plugin-catalog-react';
import { EntityDeploymentTargetPicker } from './components/catalog/CustomFilters';
import { themes, UnifiedThemeProvider } from '@backstage/theme';
import { CssBaseline } from '@material-ui/core';
import { rrThemeLight } from './themes/rrThemeLight';
import { rrThemeDark } from './themes/rrThemeDark';
import { customColumnFactories } from './components/catalog/CustomColumns';
import { NotificationsPage } from '@backstage/plugin-notifications';

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  themes: [
    {
      id: 'light',
      title: 'Light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={themes.light} children={children} />
      ),
    },
    {
      id: 'dark',
      title: 'Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={themes.dark} children={children} />
      ),
    },
    {
      id: 'rr-light',
      title: 'R&R Light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={rrThemeLight} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
    {
      id: 'rr-dark',
      title: 'R&R Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={rrThemeDark} noCssBaseline>
          <CssBaseline />
          {children}
        </UnifiedThemeProvider>
      ),
    },
  ],
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'microsoft-auth-provider',
            title: 'Microsoft',
            message: 'Sign in using Microsoft',
            apiRef: microsoftAuthApiRef,
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(insightsPlugin.externalRoutes, {
      searchPageRouteRef: searchPlugin.routes.root,
    });
  },
});


const catalogColumns: CatalogTableColumnsFunc = entityListContext => {
  let defaultColumns = CatalogTable.defaultColumnsFunc(entityListContext);
  if (entityListContext.filters.kind?.value === 'component') {
    defaultColumns = [customColumnFactories.createDeploymentTargetColumn(), ...defaultColumns];
  }
  return defaultColumns;
};

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      {homePage}
    </Route>
    <Route
      path="/catalog"
      element={
        <CatalogIndexPage
          columns={catalogColumns}
          filters={
            <>
              <DefaultFilters />
              <EntityDeploymentTargetPicker />
            </>
          }
        />
      }
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <OctopusDeployDropdownFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/explore" element={<ExplorePage />}>
      {explorePage}
    </Route>
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/devtools" element={
      <RequirePermission permission={devToolsAdministerPermission}>
        <DevToolsPage />
      </RequirePermission>}>
        {customDevToolsPage}
    </Route>
    <Route path="/entity-validation" element={<EntityValidationPage />} />
    <Route path="/rbac" element={<RBACRoot />} />
    <Route path="/insights" element={<InsightsPage />} />
    <Route path="/skill-exchange" element={<SkillExchangePage enableEmbeds enableHacks enableMentorships />} />
    <Route
      path='/soundcheck'
      element={<SoundcheckRoutingPage />}
    />
    <Route path="/notifications" element={<NotificationsPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <InsightsSurveyLoader />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
