import { createApiRef } from '@backstage/core-plugin-api';
import { Project } from './types';

export const lokaliseApiRef = createApiRef<LokaliseApi>({
  id: 'plugin.lokalise.service',
});

export interface LokaliseApi {
  getProject(projectId: string): Promise<Project>;
}
