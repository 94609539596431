import { DiscoveryApi, FetchApi } from "@backstage/core-plugin-api";
import { RadarEntry, RadarEntrySnapshot, TechRadarApi, TechRadarLoaderResponse } from "@backstage-community/plugin-tech-radar";

export class ProxyTechRadarApi implements TechRadarApi {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;

    constructor({discoveryApi, fetchApi}: {discoveryApi: DiscoveryApi, fetchApi: FetchApi}) {
        this.discoveryApi = discoveryApi;
        this.fetchApi = fetchApi;
    }

    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
        const proxyBaseUri = `${await this.discoveryApi.getBaseUrl('proxy')}/tech-radar${id ?? ""}`;
        const techRadarUri = `${proxyBaseUri}/tech-radar.json`;
        const response = await this.fetchApi.fetch(techRadarUri);
        if (response.ok) {
            const data = await response.json();
            const result: TechRadarLoaderResponse = { ...data, entries: [] };
            for (const quadrant of result.quadrants) {
                const quadrantUri = `${proxyBaseUri}/${quadrant.id}.json`;
                const quadrantResponse = await this.fetchApi.fetch(quadrantUri);
                if (quadrantResponse.ok) {
                    const quadrantData = await quadrantResponse.json();
                    result.entries.push(
                        ...quadrantData.map((entry: RadarEntry) => ({
                            ...entry,
                            key: entry.id,
                            quadrant: quadrant.id,
                            timeline: entry.timeline.map((timeline: RadarEntrySnapshot) => ({
                                ...timeline,
                                date: new Date(timeline.date),
                            })),
                        }))
                    );
                }
            }

            return result;
        }

        return {
            quadrants: [],
            rings: [],
            entries: []
        }
    }
}