import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  errorApiRef,
  fetchApiRef,
  identityApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { ProxyTechRadarApi } from './lib/ProxyTechRadarApi';
import {
  VisitsWebStorageApi,
  visitsApiRef,
} from '@backstage/plugin-home';
import { BackstageInsights } from '@spotify/backstage-plugin-analytics-module-insights';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: techRadarApiRef,
    deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
    factory: ({ discoveryApi, fetchApi }) => new ProxyTechRadarApi({ discoveryApi, fetchApi })
  }),
  // createApiFactory({
  //   api: analyticsApiRef,
  //   deps: { configApi: configApiRef, identityApi: identityApiRef },
  //   factory: ({ configApi, identityApi }) =>
  //     NewRelicBrowser.fromConfig(configApi, {
  //       identityApi,
  //     }),
  // }),
  BackstageInsights.createDefaultApiFactory(), // Replaces New Relic Browser
  createApiFactory({
    api: visitsApiRef,
    deps: {
      identityApi: identityApiRef,
      errorApi: errorApiRef
    },
    factory: ({ identityApi, errorApi }) => VisitsWebStorageApi.create({ identityApi, errorApi }),
  }),
  // ScmAuth.createDefaultApiFactory normally registers the Azure provider, but it also registers scopes that are not exposed on our app registration.
  // Requesting a token would therefore error, because the scopes are not available. The scopes to request are set manually here instead.
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      azure: microsoftAuthApiRef,
    },
    factory: ({ azure }) => {
      return ScmAuth.forAuthApi(azure, {
        host: 'dev.azure.com',
        scopeMapping: {
          default: [],
          repoWrite: [
            '499b84ac-1321-427f-aa17-267ca6975798/vso.code_manage',    // For cloning and creating PRs for existing repos through the scaffolder
            '499b84ac-1321-427f-aa17-267ca6975798/vso.build_execute',  // For creating new Azure DevOps pipelines through the scaffolder
          ]
        }
      })
    }
  })
];
