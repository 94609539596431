import {
    CatalogKindExploreContent,
    ExploreLayout,
    GroupsExplorerContent,
} from '@backstage-community/plugin-explore';
import React from 'react';
import { ToolExplorerContent } from './ToolExplorerContent';

export const ExplorePage = () => {
    return (
        <ExploreLayout
            title="Explore the R&R WFM ecosystem"
            subtitle="Discover solutions available in your ecosystem"
        >
            <ExploreLayout.Route path="domains" title="Domains">
                <CatalogKindExploreContent kind="domain" />
            </ExploreLayout.Route>
            <ExploreLayout.Route path="groups" title="Groups">
                <GroupsExplorerContent />
            </ExploreLayout.Route>
            <ExploreLayout.Route path="tools" title="Tools">
                <ToolExplorerContent />
            </ExploreLayout.Route>
        </ExploreLayout>
    );
};

export const explorePage = <ExplorePage />;